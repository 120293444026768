<template>
  <app-teleport :cover="fixedOverlay">
    <div
      class="modal-wrapper"
      :ref="(e) => refHandler(e, (h) => (modalElem = h))"
      v-class-mod:modal-wrapper="{ fixedOverlay }"
      v-stick-to="
        stickTo && {
          element: isHtmlElem(modalElem) ? modalElem : modalElem,
          stickTo: isHtmlElem(stickTo) ? stickTo : stickTo?.$el,
          align: 'right',
          placement: 'bottom',
          stick: 'left',
          ...stickToOptions,
        }
      "
    >
      <slot />
    </div>
  </app-teleport>
</template>
<script setup lang="ts">
import { ComponentPublicInstance, ref } from "vue";

import { AppTeleport } from "@horizon56/bootstrap";
import { isHtmlElem, refHandler } from "@horizon56/utils";

import { Input, vStickTo } from "@horizon56/directives/stick-to";

defineProps<{
  fixedOverlay?: boolean;
  stickTo?: ComponentPublicInstance;
  teleportOptions?: Pick<
    InstanceType<typeof AppTeleport>,
    "availablePanels" | "allowLeftMenuAccess" | "allowRightMenuAccess"
  >;
  stickToOptions?: Partial<Input>;
}>();

const modalElem = ref<HTMLElement>();
</script>

<style lang="scss">
.modal-wrapper {
  display: flex;
  flex-flow: column;
  &--fixedOverlay {
    display: flex;
    position: absolute;
    background: var(--static-black-50);
    @include position-corner;
    > * {
      margin: auto;
    }
  }
}
</style>
